import React, { useState } from "react";
import "./Quiz.css";
import Result from "../results/Result";
import "./Button.scss";
import { Helmet, HelmetProvider } from "react-helmet-async";

function Quiz({ questions }) {
    const [count, setcount] = useState(0);
    const [score, setScore] = useState(0);
    const [finalResult, setfinalResult] = useState(false);
    const [num, setnum] = useState(1);

    const correctAns = questions[count].correctAnswer;
    const allAnswers = [questions[count].correctAnswer, questions[count].incorrectAnswers];
    const mixedArray = allAnswers.flat();

    const shuffled = mixedArray
        .map((value) => ({ value, sort: Math.random() * 5 }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);

    const buttonClick = (result) => {
        setnum((prev) => prev + 1);

        correctAns.toUpperCase() === result && setScore((prev) => prev + 1);
        num === questions.length && setfinalResult(true) && correctAns === result && setScore((prev) => prev + 1);
        num < questions.length && setcount((prev) => prev + 1);

        // console.log("array", questions.length);
        // count < questions.length - 1 && setcount((prev) => prev + 1) && console.log("working first");
        // count === questions.length - 1 && setcount((prev) => prev) && setfinalResult(true) && console.log("working second");
        // count > questions.length + 1 && setfinalResult(true);
        // +count + 1 > questions.length && !finalResult
        //     ? setcount((prev) => (prev == questions.length ? 10 : prev + 1))
        //     : setfinalResult(true);
    };
    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Lottie Quiz</title>
                    <meta name="description" content="Quizz for all" />
                    <meta
                        name="keywords"
                        content="quiz, gk, questions, answers, submit, win, general, general,knowledge, paper, exam, psc, ugc, degree,graduation, 10th,12th, sslc, bank"
                    />
                </Helmet>
                {!finalResult ? (
                    <div className="row">
                        <div className="container">
                            <div className="ques">
                                <h1>{questions[count].question}</h1>
                            </div>
                            <div className="ans">
                                {/* <button className="btn spcl button">{questions[count].correctAnswer}</button> */}
                                {questions.length > count &&
                                    shuffled.map((val, i) => {
                                        return (
                                            <div key={i} className="btndiv">
                                                <button
                                                    onClick={(e) => buttonClick(e.target.innerText)}
                                                    className="btn button"
                                                >
                                                    {val}
                                                </button>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                ) : (
                    <Result score={score} totalQues={questions.length} />
                )}
            </HelmetProvider>
        </div>
    );
}

export default Quiz;
