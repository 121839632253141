import React, { useState, useEffect } from "react";
import axios from "axios";
import Quiz from "../quiz/Quiz";
import Loader from "../loader/Loader";
import Error from "../error/Error";
import { Helmet, HelmetProvider } from "react-helmet-async";

function Api({ inputVal }) {
    const [questions, setquestions] = useState([{}]);
    const [loader, setLoader] = useState(true);
    const [errorState, setErrorState] = useState(false);
    useEffect(() => {
        axios
            .get(`https://the-trivia-api.com/api/questions?limit=${inputVal !== "" ? inputVal : 10}`)
            .then((result) => {
                const data = result.data;
                setquestions(data);
                setLoader(false);
            })
            .catch((error) => {
                setErrorState(true);
                console.clear();
            });
    }, [inputVal]);

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Lottie Quizz</title>
                    <meta name="description" content="Quizz for all" />
                    <meta
                        name="keywords"
                        content="quiz, gk, questions, answers, submit, win, general, general,knowledge, paper, exam, psc, ugc, degree,graduation, 10th,12th, sslc, bank"
                    />
                </Helmet>
                {loader ? errorState ? <Error /> : <Loader /> : questions[0].id !== "" && <Quiz questions={questions} />}
            </HelmetProvider>
        </div>
    );
}

export default Api;
