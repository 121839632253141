import React from "react";
import "./Error.css";

function Error() {
    console.clear();
    return (
        <div>
            <div className="row2">
                <div className="container2">
                    <div className="result2">
                        <h1 className="resScore2"> Something Went Wrong ! Contact me@soorajsr.com 🚫</h1>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Error;
