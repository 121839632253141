import React from "react";
import Start from "./components/start/Start";
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Lottie Quizz</title>
                    <meta name="description" content="Quizz for all" />
                    <meta
                        name="keywords"
                        content="quiz, gk, questions, answers, submit, win, general, general,knowledge, paper, exam, psc, ugc, degree,graduation, 10th,12th, sslc, bank"
                    />
                </Helmet>
                <Start />
            </HelmetProvider>
        </div>
    );
}

export default App;
