import React, { useState } from "react";
// import "./Result.css";
import "./Button.scss";
import App from "../../App";

function Result({ score = 300, totalQues = 0 }) {
    const [playAgain, setplayAgain] = useState(false);

    const replay = () => {
        !playAgain ? setplayAgain(true) : playAgain(false);
    };
    return (
        <div>
            {!playAgain ? (
                <div className="row2">
                    <div className="container2">
                        <div className="result2">
                            <h1 className="resScore">
                                Your Score : {score}/{totalQues} 🏆
                            </h1>
                        </div>
                        <div className="playAgain">
                            <button className="btn2 button" onClick={replay}>
                                Play Again
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <App />
            )}
        </div>
    );
}

export default Result;
