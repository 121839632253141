import React from "react";
import "./Loader.css";

function Loader() {
    return (
        <div className="row">
            <div className="container">
                <div className="loaders">
                    <div className="loader-1"></div>
                    <div className="loader-2"></div>
                    <div className="loader-3"></div>
                </div>
            </div>
        </div>
    );
}
export default Loader;
