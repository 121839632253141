import React, { useState } from "react";
import "./Start.css";
import Api from "../api/Api";

function Start() {
    const [startNow, setStartNow] = useState(false);
    const [inputVal, setInputVal] = useState("");
    const loader = () => {
        (!startNow && inputVal > 0 && inputVal < 501) || (!startNow && inputVal === "")
            ? setStartNow(true)
            : setStartNow(false);
    };

    const enterKeyFunc = (e) => {
        e.charCode === 13 && loader();
    };
    return (
        <div>
            {!startNow ? (
                <div className="row3">
                    <div className="container-3">
                        <div className="start">
                            <div className="input">
                                <label className="introText" htmlFor="">
                                    How Many Questions You Want ?
                                </label>{" "}
                                <br />
                                <label className="description">
                                    {inputVal === "" || (inputVal > 0 && inputVal < 501)
                                        ? "(Default: 10)"
                                        : "Enter a Number Between 1 - 500"}{" "}
                                </label>
                                <br />
                                <input
                                    placeholder="Num"
                                    value={inputVal}
                                    className="ifield"
                                    type="number"
                                    onChange={(e) => setInputVal(e.target.value)}
                                    onKeyPress={(e) => enterKeyFunc(e)}
                                />
                            </div>

                            <div>
                                <button onClick={loader} className="bttn">
                                    Start
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Api inputVal={inputVal} />
            )}
        </div>
    );
}

export default Start;
